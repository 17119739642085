.about {
  border-radius: 32px;
  background: linear-gradient(180deg, #ff5160 0%, #ff3648 100%);
}

#product-card {
  box-shadow: 0px 10px 21px 0px rgba(0, 0, 0, 0.02),
    0px 38px 38px 0px rgba(0, 0, 0, 0.02), 0px 86px 52px 0px rgba(0, 0, 0, 0.01),
    0px 153px 61px 0px rgba(0, 0, 0, 0), 0px 239px 67px 0px rgba(0, 0, 0, 0);
}

#price-card {
  box-shadow: 0px 10px 21px 0px rgba(0, 0, 0, 0.01),
    0px 38px 38px 0px rgba(0, 0, 0, 0.01), 0px 86px 52px 0px rgba(0, 0, 0, 0.01),
    0px 153px 61px 0px rgba(0, 0, 0, 0), 0px 239px 67px 0px rgba(0, 0, 0, 0);
}

.swiper-pagination-bullet-active {
  background-color: #ff3c4d !important;
}

.swiper-pagination-bullet {
  background-color: #ff3c4d;
}

.text-comment {
  box-shadow: 0px 7px 16px 0px rgba(0, 0, 0, 0.01),
    0px 29px 29px 0px rgba(0, 0, 0, 0.01), 0px 64px 38px 0px rgba(0, 0, 0, 0.01),
    0px 114px 46px 0px rgba(0, 0, 0, 0), 0px 178px 50px 0px rgba(0, 0, 0, 0);
}

#navbar {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.02),
    0px 7px 7px 0px rgba(0, 0, 0, 0.02), 0px 16px 10px 0px rgba(0, 0, 0, 0.01),
    0px 29px 12px 0px rgba(0, 0, 0, 0), 0px 45px 13px 0px rgba(0, 0, 0, 0);
}

.mega-menu {
  border-radius: 12px;
  border: 0.5px solid var(--Gray-200, #f2f4f7);
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.03),
    0px 10px 10px 0px rgba(0, 0, 0, 0.03), 0px 22px 13px 0px rgba(0, 0, 0, 0.02),
    0px 39px 16px 0px rgba(0, 0, 0, 0), 0px 61px 17px 0px rgba(0, 0, 0, 0);
}

#title-pricing {
  background: linear-gradient(180deg, #ff3c4d 0%, #fd6673 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
