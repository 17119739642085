@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .text-7xl-extrabold {
    @apply font-Jakarta font-extrabold text-[68px] tracking-[-1.44px] leading-115;
  }
  .text-7xl-bold {
    @apply font-Jakarta font-bold text-[68px] tracking-[-1.44px] leading-115;
  }
  .text-7xl-semibold {
    @apply font-Jakarta font-semibold text-[68px] tracking-[-1.44px] leading-115;
  }
  .text-7xl-medium {
    @apply font-Jakarta font-medium text-[68px] tracking-[-1.44px] leading-115;
  }
  .text-7xl-regular {
    @apply font-Jakarta font-normal text-[68px] tracking-[-1.44px] leading-115;
  }

  .text-6xl-extrabold {
    @apply font-Jakarta font-extrabold text-[64px] tracking-[-1.44px]  leading-115;
  }
  .text-6xl-bold {
    @apply font-Jakarta font-bold text-[64px] tracking-[-1.44px] leading-115;
  }
  .text-6xl-semibold {
    @apply font-Jakarta font-semibold text-[64px] tracking-[-1.44px] leading-115;
  }
  .text-6xl-medium {
    @apply font-Jakarta font-medium text-[64px] tracking-[-1.44px] leading-115;
  }
  .text-6xl-regular {
    @apply font-Jakarta font-normal text-[64px] tracking-[-1.44px] leading-115;
  }

  .text-5xl-extrabold {
    @apply font-Jakarta font-extrabold text-[48px] tracking-[-1.44px] leading-115;
  }
  .text-5xl-bold {
    @apply font-Jakarta font-bold text-[48px] tracking-[-1.44px]  leading-115;
  }
  .text-5xl-semibold {
    @apply font-Jakarta font-semibold text-[48px] tracking-[-1.44px] leading-115;
  }
  .text-5xl-medium {
    @apply font-Jakarta font-medium text-[48px] tracking-[-1.44px] leading-115;
  }
  .text-5xl-regular {
    @apply font-Jakarta font-normal text-[48px] tracking-[-1.44px] leading-115;
  }

  .text-4xl-extrabold {
    @apply font-Jakarta font-extrabold text-[40px] tracking-[-1.44px] leading-115;
  }
  .text-4xl-bold {
    @apply font-Jakarta font-bold text-[40px] tracking-[-1.44px] leading-115;
  }
  .text-4xl-semibold {
    @apply font-Jakarta font-semibold text-[40px] tracking-[-1.44px] leading-115;
  }
  .text-4xl-medium {
    @apply font-Jakarta font-medium text-[40px] tracking-[-1.44px] leading-115;
  }
  .text-4xl-regular {
    @apply font-Jakarta font-normal text-[40px] tracking-[-1.44px] leading-115;
  }

  .text-3xl-extrabold {
    @apply font-Jakarta font-extrabold text-[32px] tracking-[-0.384px] leading-120;
  }
  .text-3xl-bold {
    @apply font-Jakarta font-bold text-[32px] tracking-[-0.384px] leading-120;
  }
  .text-3xl-semibold {
    @apply font-Jakarta font-semibold text-[32px] tracking-[-0.384px] leading-120;
  }
  .text-3xl-medium {
    @apply font-Jakarta font-medium text-[32px] tracking-[-0.384px] leading-120;
  }
  .text-3xl-regular {
    @apply font-Jakarta font-normal text-[32px] tracking-[-0.384px] leading-120;
  }

  /* new */
  .text-3xll-extrabold {
    @apply font-Jakarta font-extrabold text-[28px] tracking-[-0.384px] leading-120;
  }
  .text-3xll-bold {
    @apply font-Jakarta font-bold text-[28px] tracking-[-0.384px] leading-120;
  }
  .text-3xll-semibold {
    @apply font-Jakarta font-semibold text-[28px] tracking-[-0.384px] leading-120;
  }
  .text-3xll-medium {
    @apply font-Jakarta font-medium text-[28px] tracking-[-0.384px] leading-120;
  }
  .text-3xll-regular {
    @apply font-Jakarta font-normal text-[28px] tracking-[-0.384px] leading-120;
  }

  /* new */
  .text-2xl-extrabold {
    @apply font-Jakarta font-extrabold text-[24px] tracking-[-0.384px] leading-120;
  }
  .text-2xl-bold {
    @apply font-Jakarta font-bold text-[24px] tracking-[-0.384px] leading-120;
  }
  .text-2xl-semibold {
    @apply font-Jakarta font-semibold text-[24px] tracking-[-0.384px] leading-120;
  }
  .text-2xl-medium {
    @apply font-Jakarta font-medium text-[24px] tracking-[-0.384px] leading-120;
  }
  .text-2xl-regular {
    @apply font-Jakarta font-normal text-[24px] tracking-[-0.384px] leading-120;
  }

  .text-xl-extrabold {
    @apply font-Jakarta font-extrabold text-[20px] tracking-[-0.24px] leading-140;
  }
  .text-xl-bold {
    @apply font-Jakarta font-bold text-[20px] tracking-[-0.24px] leading-140;
  }
  .text-xl-semibold {
    @apply font-Jakarta font-semibold text-[20px] tracking-[-0.24px] leading-140;
  }
  .text-xl-medium {
    @apply font-Jakarta font-medium text-[20px] tracking-[-0.24px] leading-140;
  }
  .text-xl-regular {
    @apply font-Jakarta font-normal text-[20px] tracking-[-0.24px] leading-140;
  }
  /* heading group */

  /* body group */
  .text-lg-extrabold {
    @apply font-Jakarta font-extrabold text-[18px] tracking-[-0.09px] leading-140;
  }
  .text-lg-bold {
    @apply font-Jakarta font-bold text-[18px] tracking-[-0.09px] leading-140;
  }
  .text-lg-semibold {
    @apply font-Jakarta font-semibold text-[18px] tracking-[-0.09px] leading-140;
  }
  .text-lg-medium {
    @apply font-Jakarta font-medium text-base md:text-[18px] tracking-[-0.09px] leading-140;
  }
  .text-lg-regular {
    @apply font-Jakarta font-normal text-[18px] tracking-[-0.09px] leading-140;
  }

  .text-base-extrabold {
    @apply font-Jakarta font-extrabold text-[16px] tracking-[-0.09px] leading-140;
  }
  .text-base-bold {
    @apply font-Jakarta font-bold text-[16px] tracking-[-0.09px] leading-140;
  }
  .text-base-semibold {
    @apply font-Jakarta font-semibold text-[16px] tracking-[-0.09px] leading-140;
  }
  .text-base-medium {
    @apply font-Jakarta font-medium text-[16px] tracking-[-0.09px] leading-140;
  }
  .text-base-regular {
    @apply font-Jakarta font-normal text-[16px] tracking-[-0.09px] leading-140;
  }
  /* body group */

  /* small group */
  .text-small-extrabold {
    @apply font-Jakarta font-extrabold text-[14px] tracking-wide;
  }
  .text-small-bold {
    @apply font-Jakarta font-bold text-[14px] tracking-wide;
  }
  .text-small-semibold {
    @apply font-Jakarta font-semibold text-[14px] tracking-wide;
  }
  .text-small-medium {
    @apply font-Jakarta font-medium text-[14px] tracking-wide;
  }
  .text-small-regular {
    @apply font-Jakarta font-normal text-[14px] tracking-wide;
  }

  .text-xs-extrabold {
    @apply font-Jakarta font-extrabold text-[12px] tracking-wide;
  }
  .text-xs-bold {
    @apply font-Jakarta font-bold text-[12px] tracking-wide;
  }
  .text-xs-semibold {
    @apply font-Jakarta font-semibold text-[12px] tracking-wide;
  }
  .text-xs-medium {
    @apply font-Jakarta font-medium text-[12px] tracking-wide;
  }
  .text-xs-regular {
    @apply font-Jakarta font-normal text-[12px] tracking-wide;
  }

  .text-xxs-extrabold {
    @apply font-Jakarta font-extrabold text-[10px] tracking-wide;
  }
  .text-xxs-bold {
    @apply font-Jakarta font-bold text-[10px] tracking-wide;
  }
  .text-xxs-semibold {
    @apply font-Jakarta font-semibold text-[10px] tracking-wide;
  }
  .text-xxs-medium {
    @apply font-Jakarta font-medium text-[10px] tracking-wide;
  }
  .text-xxs-regular {
    @apply font-Jakarta font-normal text-[10px] tracking-wide;
  }
  /* small group */
}
/* heading group */
